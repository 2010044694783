import React from "react"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

import "./projectcontainer_.css"

import ContentContainer from "./contentcontainer"
import Close from "../close"

function ProjectContainer(props) {
  const state = { fade_in: true }
  var project_data = props.project_data
  var project_top = props.top
  var projectContainerStyle = {
    // top: project_top + "px",
    color: props.bg,
    backgroundColor: "currentColor"
  }
  var closeProject = props.closeProject
  var overlayd = "project-container"

  const ref = React.useRef(null)
  /*
  if (props.overlayd) {
    overlayd = overlayd + " overlayd"
  }
  if (state.fade_in) {
    overlayd = overlayd + " fade-in"
  }
  */
  var Close_btn = props.overlayd ? (
    <Close closeProject={closeProject} key="close" />
  ) : null

  React.useLayoutEffect(() => {
    if (ref.current) disableBodyScroll(ref.current)
    return () => clearAllBodyScrollLocks()
  }, [])

  return (
    <div className={overlayd} style={projectContainerStyle} ref={ref}>
      <div className="outer-content-container">
        {Close_btn}
        <ContentContainer project_data={project_data} key="content" />
      </div>
    </div>
  )
}

export default ProjectContainer
