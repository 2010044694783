import React, { PureComponent, memo, useState } from "react"
import dimensionsToServe, { whatSizeToServe } from "../utilities/imagesize"
import styled, { css, keyframes } from "styled-components"

export const ImageDimensionsAndUrl = memo(
  ({ width, height, canvas, url: src, children }) => {
    const dimensions = dimensionsToServe(canvas)
    console.log(canvas, dimensions)

    const url = `//images.weserv.nl/?url=rudinswagerman.nl/${src}&w=${dimensions.width}`
    return children({ ...dimensions, url })
  }
)

// Fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

// Wrapper styled component to maintain layout
const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

// Styled Image with cover and animation
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  animation: ${(props) => (props.loaded ? fadeIn : "none")} 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
`

const FadeImage = ({ src, alt = "Image" }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <ImageWrapper>
      <StyledImage
        src={src}
        alt={alt}
        loaded={loaded}
        onLoad={() => setLoaded(true)}
      />
    </ImageWrapper>
  )
}

const ImageYeah = memo(({ width, height, canvas, url }) => {
  return (
    <ImageDimensionsAndUrl
      width={width}
      height={height}
      canvas={canvas}
      url={url}
    >
      {({ url }) => <FadeImage src={url} />}
    </ImageDimensionsAndUrl>
  )
})

export default ImageYeah
