import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

const Dimensions = ({ children, ...props }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const containerRef = useRef(null)

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { offsetWidth, offsetHeight } = containerRef.current
        setDimensions({ width: offsetWidth, height: offsetHeight })
      }
    }

    // Initial update
    updateDimensions()

    // Update on resize
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  return (
    <div ref={containerRef} {...props}>
      {children(dimensions)}
    </div>
  )
}
Dimensions.propTypes = {
  children: PropTypes.func.isRequired,
}

export default Dimensions
