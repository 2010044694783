import React, { Fragment } from "react"
import scalableGrid from "../utilities/scalablegrid"

import "./thumbscontainer_.css"

import Thumbnail from "./thumbnail"

const ThumbsContainer = props => {
  const [width, setWidth] = React.useState(0)
  const ref = React.useRef(null)

  const updateDimensions = () => {
    // this.setState({width: window.innerWidth, height: window.innerHeight});

    if (ref.current) {
      var elementWidth = ref.current.getBoundingClientRect().width
      setWidth(elementWidth)
    }

    // console.log(this.refs.thumbs.getDOMNode());
  }

  React.useLayoutEffect(() => {
    updateDimensions()
    window.addEventListener("resize", updateDimensions)
    return () => {
      window.removeEventListener("resize", updateDimensions)
    }
  }, [])

  var projects = props.projects

  var thumbnail_rows = scalableGrid.thumbSizing(
    projects,
    width,
    0.65
  )

  var added_one = width + 1
  return (
    <Fragment>
      <div ref={ref} style={{ width: "100%", height: 0 }} />
      {width > 0 && (
        <div className="thumbs-container">
          <div className="width-fixer" style={{ width: added_one + "px" }}>
            {
              thumbnail_rows?.map(
                (row, index) => <React.Fragment key={index}>

                  {row.map(
                    (thumbnail, index2) => <Thumbnail
                      container_width={width}
                      width={thumbnail.width}
                      project_id={thumbnail.id}
                      showProject={props.showProject}
                      showArchive={props.showArchive}
                      thumb_data={thumbnail}
                      project_data={projects[thumbnail.id]}
                      key={thumbnail.slug + "-" + index2}
                    />
                  )
                  }
                </React.Fragment>
              )
            }
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default ThumbsContainer
