import React from "react"
import { Link } from "@reach/router"

import "./close_.css"

import MobileDetect from "../utilities/mobiledetect"

/* <button className="rs__button rs__button--close" title="Close" onClick={this.handleClick}/> */

export default function Close(props) {
  if (MobileDetect()) {
    return (
      <Link
        to="/"
        className="rs__button rs__button--close"
        title="Close"
        style={{ top: 14, right: 14 }}
      />
    )
  } else {
    return (
      <Link
        to="/"
        className="rs__button rs__button--close"
        title="Close"
        style={{ top: 14, right: 14 }}
      />
    )
  }
}
