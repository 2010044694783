import React from "react"

import "./entity_.css"

export default class Entity extends React.Component {
  constructor(props) {
    super(props)
    this._triggerPositionChange4All = this._triggerPositionChange4All.bind(this)
  }
  state = this.getInitialState()
  getInitialState() {
    var color = this.randomColor()
    var position = this.randomPosition()
    var combined = {
      r: color.r,
      g: color.g,
      b: color.b,
      left: position.left,
      top: position.top
    }
    return combined
  }
  UNSAFE_componentWillUpdate(props) {
    if (props.color > this.props.color) {
      this.entityColor()
    }
    if (props.position > this.props.position) {
      this.entityPosition()
    }
    if (props.both > this.props.both) {
      this.entityColor()
      this.entityPosition()
    }
    /*
    this.setState({
      width: props.thumb_data.width,
      height: props.thumb_data.height
    });
    */
  }
  randomIntensity() {
    return Math.round(Math.random() * 256)
  }
  randomDistance() {
    return Math.round(Math.random() * 100) + "%"
  }
  randomColor() {
    return {
      r: this.randomIntensity(),
      g: this.randomIntensity(),
      b: this.randomIntensity()
    }
  }
  randomPosition() {
    return {
      left: this.randomDistance(),
      top: this.randomDistance()
    }
  }
  entityColor = () => {
    this.setState(this.randomColor())
  }
  entityPosition = () => {
    this.setState(this.randomPosition())
  }
  render() {
    var r = this.state.r
    var g = this.state.g
    var b = this.state.b
    var entityStyle = {
      left: this.state.left,
      top: this.state.top
    }
    var contentStyle = {
      backgroundColor: "rgb(" + r + "," + g + "," + b + ")"
    }
    return (
      <div
        className={"entity transitionPosition " + this.props.mini}
        style={entityStyle}
        onClick={this._triggerPositionChange4All}
        onMouseEnter={this.entityColor}
        onMouseLeave={this.entityPosition}
      >
        <div
          className="entity-content transitionBackgroundColor"
          style={contentStyle}
        />
      </div>
    )
  }
  _onChange(something) {
    switch (something) {
      case "position":
        this.entityPosition()
        break
      case "color":
        this.entityColor()
        break
      default:
    }
    // this.setState(getEntityState());
  }
  _triggerPositionChange4All() {
    this.props.changePositions()
  }
}
