import React from "react"

import "./link_.css" // css

function LinkBlock(props) {
  var prepend = ""
  if (typeof props.block_content.prepend != "undefined") {
    prepend = props.block_content.prepend + " "
  }
  return (
    <div className="link">
      {prepend}
      <a href={props.block_content.link} target="_blank">
        {props.block_content.title}
      </a>
    </div>
  )
}

export default LinkBlock
