import React from "react"
import { Link } from "@reach/router"

import "./thumbnail_.css"

import Excerpt from "./excerpt"
import ConfettiContainer from "./confetti/confetticontainer"
import MobileDetect from "../utilities/mobiledetect"

import Image from "./Image"

class Thumbnail extends React.Component {
  handleArchiveClick = () => {
    this.props.showArchive()
  }
  componentWillReceiveProps(props) {
    this.setState({
      width: props.thumb_data.width,
      height: props.thumb_data.height,
    })
  }
  image(project, canvas) {
    var project_in_thumb_style = {
      overflow: "hidden",
      width: "100%",
      height: "100%",
      position: "absolute",
      cursor: "pointer",
    }
    if (project.slug == "confetti") {
      return (
        <div style={project_in_thumb_style}>
          <ConfettiContainer
            mini={true}
            style={{ width: "100%", height: "100%" }}
            key={"confetti_thumb"}
          />
        </div>
      )
    } else if (typeof project.thumbnail != "undefined" && canvas.width) {
      return (
        <Image
          width={2600}
          height={2600 / project.aspect_ratio}
          canvas={canvas}
          url={"thumbnails/" + project.thumbnail + ".jpg"}
        />
      )
      return (
        <img
          src={"thumbnails/" + project.thumbnail + ".jpg"}
          width="100%"
          height="100%"
        />
      )
    }
    return null
  }
  render() {
    var thumbnail = this.props.thumb_data
    var project = this.props.project_data
    var thumb_styles = {
      minWidth: thumbnail.width + "px",
      maxWidth: thumbnail.width + "px",
      height: thumbnail.height + "px",
      marginTop: thumbnail.top + "px",
      marginRight: thumbnail.right + "px",
      marginBottom: thumbnail.bottom + "px",
    }
    var image = this.image(project, { ...thumbnail })
    if (thumbnail.first) {
      // thumb_styles.clear = "both"
    }
    if (project.slug == "archive") {
      thumb_styles.cursor = "pointer"
      return (
        <a
          className="thumbnail"
          style={thumb_styles}
          onClick={this.handleArchiveClick}
        >
          {image}
          <Excerpt project_data={project} />
        </a>
      )
    } else if (typeof project.inactive != "undefined") {
      return (
        <div className="thumbnail" style={thumb_styles}>
          {image}
          <Excerpt project_data={project} />
        </div>
      )
    } else if (MobileDetect()) {
      return (
        <Link
          to={"/" + project.slug}
          params={{ slug: project.slug }}
          className="thumbnail"
          style={thumb_styles}
        >
          {image}
          <Excerpt project_data={project} />
        </Link>
      )
    } else {
      return (
        <Link
          to={"/" + project.slug}
          params={{ slug: project.slug }}
          className="thumbnail"
          style={thumb_styles}
        >
          {image}
          <Excerpt project_data={project} />
        </Link>
      )
    }
  }
}

export default Thumbnail
