import React from "react"

import "./paragraph_.css" // css

function Paragraph(props) {
  return (
    <p
      className="paragraph"
      dangerouslySetInnerHTML={{ __html: props.block_content.paragraph }}
    />
  )
}

export default Paragraph
