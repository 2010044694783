import React from "react"
import Entity from "./entity"

import "./confetticontainer_.css"
import "./black-dot.css"

export default class ConfettiContainer extends React.Component {
  state = this.getInitialState()
  getInitialState() {
    return { color: 0, position: 0, both: 0 }
  }
  changeColors = () => {
    var color = this.state.color + 1
    this.setState({
      color: color
    })
  }
  changePositions = () => {
    var position = this.state.position + 1
    this.setState({
      position: position
    })
  }
  changeBoth = () => {
    var both = this.state.both + 1
    this.setState({
      both: both
    })
  }
  render() {
    var EntityData = [
      { title: "one" },
      { title: "two" },
      { title: "three" },
      { title: "four" },
      { title: "five" },
      { title: "six" },
      { title: "seven" },
      { title: "eight" },
      { title: "nine" },
      { title: "ten" },
      { title: "eleven" },
      { title: "twelve" }
    ]
    var color = this.state.color
    var position = this.state.position
    var both = this.state.both
    var changePositions = this.changePositions
    var mini = typeof this.props.mini != "undefined" ? "mini" : null
    var entityNodes = EntityData.map(function(entity, index) {
      return (
        <Entity
          mini={mini}
          title={entity.title}
          key={index}
          color={color}
          position={position}
          both={both}
          changePositions={changePositions}
        />
      )
    })
    return (
      <div className="confetti-container">
        <div className={"black-dot " + mini} onClick={this.changeColors} />
        <div className={"black-dot " + mini} onClick={this.changePositions} />
        <div className={"black-dot " + mini} onClick={this.changeBoth} />
        {entityNodes}
      </div>
    )
  }
}
