import { lighten, darken, desaturate } from "polished"

const blue = "rgb(13, 165, 223)"
const orange = "rgb(254, 127, 1)"
const black = "rgb(0,0,0)"

export const colors = {
  primary: blue,
  primaryHover: lighten(0.1, blue),
  primaryText: "#FFFFFF",
  secondary: "#FFFFFF",
  secondaryHover: lighten(0.3, blue),
  secondaryText: lighten(0.2, black),
  alternate: "#737373",
  alternateHover: "#A6A6A6",
  alternateText: "rgb(168, 166, 190)",
  accent: "rgb(248, 248, 248)",
  secondaryAccent: "rgb(227, 244, 254)",
  alternateAccent: "rgb(70, 64, 88)",
  text: lighten(0.2, black),
  textInvert: "#FFFFFF",
  textPlaceholder: "#DDDDDD",
  background: "#FFFFFF",
  secondaryBackground: "#e8e8e8",
  backgroundInvert: "#000000",
  overlay: "#61618A",
  border: "#000000",
  disabled: "#D9D9D9",
  shadow: "#000000",
  blue,
  orange
}

export const breakpoints = [32, 48, 64, 80].map(n => n + "em")

export const space = [0, 4, 8, 16, 32, 64, 128, 164]

export const fontSizes = [10, 11, 14, 17, 21, 24, 32, 40, 48, 64, 72, 96]

const fontCollection = {
  helveticaNeue: {
    fontFamily: `"HelveticaNeue","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif`,
    light: 300,
    normal: 400,
    bold: 500,
    fontSize: 14,
    lineHeight: 1.5,
    letterSpacing: 0,
    color: "#636183",
    textTransform: "uppercase"
  },
  helveticaNeueBold: {
    fontFamily: `HelveticaNeue-Bold","Helvetica Neue Bold","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif`,
    light: 300,
    normal: 400,
    bold: 500,
    fontSize: 14,
    lineHeight: 1.5,
    letterSpacing: 0,
    color: "#636183",
    textTransform: "uppercase"
  }
}

export const fontWeights = {
  light: 300,
  normal: 400,
  bold: 700
}

export const textStyles = {
  body: {
    ...fontCollection.helveticaNeue,
    fontWeight: "normal"
  },
  title: {
    ...fontCollection.helveticaNeue,
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: 1.4
  },
  headline: {
    ...fontCollection.helveticaNeue,
    fontSize: 21,
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: 0.14
  },
  callout: fontCollection.helveticaNeue,
  subhead: {
    ...fontCollection.helveticaNeue,
    fontSize: fontSizes[3],
    fontWeight: "normal"
  },
  footnote: { ...fontCollection.helveticaNeue, fontSize: 10 },
  label: {
    ...fontCollection.helveticaNeue,
    fontSize: 11,
    fontWeight: "normal",
    letterSpacing: 0.02
  },
  caption: {
    ...fontCollection.helveticaNeue,
    fontSize: fontSizes[0],
    fontWeight: "normal",
    letterSpacing: 0.02
  }
}

export const radii = [0, 4, 8]

export const shadows = ["none", "0 10px 30px 0 rgba(0,0,0,.07)"]

const maxWidth = `
    max-width: 160vmin;
    margin: 0 auto;
  `

export default {
  maxWidth,
  breakpoints,
  space,
  textStyles,
  fontSizes,
  fontWeights,
  colors,
  radii,
  shadows
}
