import React from "react"

import "./excerpt_.css"

const Excerpt = props => {
  var project = props.project_data
  var title = project.title
  var excerpt = typeof project.excerpt != "undefined" ? project.excerpt : ""
  var dash =
    title.length > 0 && excerpt.length > 0 ? (
      <span dangerouslySetInnerHTML={{ __html: " &#8212; " }} />
    ) : (
      ""
    )
  return (
    <div className="thumbnail-excerpt">
      <span className="title">{title}</span>
      {dash}
      <span dangerouslySetInnerHTML={{ __html: excerpt }} />
    </div>
  )
}

export default Excerpt
