import React, { Component, Fragment } from "react"
import projects_data from "../projects-data"
import { Helmet } from "react-helmet"

import ProjectContainer from "./project/projectcontainer"

export default class Project extends Component {
  state = {
    scrollY: window.scrollY,
  }
  render() {
    var top = this.state.scrollY
    var projects = projects_data
    /*
    for (var i = 0; i < projects_data.length; i++) {
      if (typeof projects_data[i].hidden == "undefined") {
        if (
          this.state.show_archive ||
          (!this.state.show_archive &&
            typeof projects_data[i].archived == "undefined")
        ) {
          projects.push(projects_data[i])
        }
      }
    }
    */
    const project = projects.find((project) => project.slug === this.props.slug)

    const ActiveProject = project && (
      <ProjectContainer
        key={"slug_" + project.slug}
        overlayd={true}
        project_data={project}
        id={project.slug}
        top={top}
        closeProject={this.closeProject}
        bg={project.background_color || "white"}
      />
    )

    return (
      <Fragment>
        {!!project && (
          <Helmet>
            <title>{project.title} ⚬ Rudin</title>
            <meta name="og:title" content={`${project.title} ⚬ Rudin`} />
            <meta name="description" content={project.description} />
            <meta name="og:description" content={project.description} />
          </Helmet>
        )}
        {ActiveProject}
      </Fragment>
    )
  }
}
