// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    name: "Hair & Make Up",
    artists: [
      {
        name: "Alexander van der Heide",
        to: "https://www.houseoforange.nl/artist/alexander-van-der-heide",
      },
      {
        name: "Britt Breider",
        to: "https://www.houseoforange.nl/artist/britt-breider",
      },
      {
        name: "Chris Völkers",
        to: "https://www.houseoforange.nl/artist/chris-volkers",
      },
      {
        name: "Emma de Boer",
        to: "https://www.houseoforange.nl/artist/emma-de-boer",
      },
      {
        name: "Eva Copper",
        to: "https://www.houseoforange.nl/artist/eva-copper",
      },
      {
        name: "Iljitsj Oppatja",
        to: "https://www.houseoforange.nl/artist/iljitsj-oppatja",
      },
      {
        name: "Ingrid van Hemert",
        to: "https://www.houseoforange.nl/artist/ingrid-van-hemert",
      },
      {
        name: "Irena Ruben",
        to: "https://www.houseoforange.nl/artist/irena-ruben",
      },
      {
        name: "Jody Cuberli",
        to: "https://www.houseoforange.nl/artist/jody-cuberli",
      },
      {
        name: "Kato Fierkens",
        to: "https://www.houseoforange.nl/artist/kato-fierkens",
      },
      {
        name: "Leanne van Niekerk",
        to: "https://www.houseoforange.nl/artist/leanne-van-niekerk",
      },
      {
        name: "Lydia le Loux",
        to: "https://www.houseoforange.nl/artist/lydia-le-loux",
      },
      {
        name: "Marly van den Bosch",
        to: "https://www.houseoforange.nl/artist/marly-van-den-bosch",
      },
      {
        name: "Mascha Meyer",
        to: "https://www.houseoforange.nl/artist/mascha-meyer",
      },
      {
        name: "Mathieu Bronckhorst",
        to: "https://www.houseoforange.nl/artist/mathieu-bronckhorst",
      },
      {
        name: "Pernell Kusmus",
        to: "https://www.houseoforange.nl/artist/pernell-kusmus",
      },
      {
        name: "Sarah Cloe",
        to: "https://www.houseoforange.nl/artist/sarah-cloe",
      },
      {
        name: "Sophie Wortelboer",
        to: "https://www.houseoforange.nl/artist/sophie-wortelboer",
      },
      {
        name: "Vannessa Chan",
        to: "https://www.houseoforange.nl/artist/vannessa-chan",
      },
    ],
  },
  {
    name: "Style",
    artists: [
      {
        name: "Anna Claassen",
        to: "https://www.houseoforange.nl/artist/anna-claassen",
      },
      {
        name: "Elle Verhagen",
        to: "https://www.houseoforange.nl/artist/elle-verhagen",
      },
      {
        name: "Equiline van Dooren",
        to: "https://www.houseoforange.nl/artist/equiline-van-dooren",
      },
      {
        name: "Jos van Heel",
        to: "https://www.houseoforange.nl/artist/jos-van-heel",
      },
      {
        name: "Katerina Familiarskaia",
        to: "https://www.houseoforange.nl/artist/katerina-familiarskaia",
      },
      {
        name: "Lidewij Merckx",
        to: "https://www.houseoforange.nl/artist/lidewij-merckx",
      },
      {
        name: "Lieve Gerrits",
        to: "https://www.houseoforange.nl/artist/lieve-gerrits",
      },
      {
        name: "Lobke de Dreu",
        to: "https://www.houseoforange.nl/artist/lobke-de-dreu",
      },
      {
        name: "Marleen Ettema",
        to: "https://www.houseoforange.nl/artist/marleen-ettema",
      },
      {
        name: "Olivier Jehee",
        to: "https://www.houseoforange.nl/artist/olivier-jehee",
      },
      {
        name: "Reineke Groters",
        to: "https://www.houseoforange.nl/artist/reineke-groters",
      },
      {
        name: "Renske van der Ploeg",
        to: "https://www.houseoforange.nl/artist/renske-van-der-ploeg",
      },
      {
        name: "Siriane Hunia",
        to: "https://www.houseoforange.nl/artist/siriane-hunia",
      },
      {
        name: "Suze Kuit",
        to: "https://www.houseoforange.nl/artist/suze-kuit",
      },
    ],
  },
  {
    name: "Nails",
    artists: [
      {
        name: "Daniel Smedeman",
        to: "https://www.houseoforange.nl/artist/daniel-smedeman",
      },
    ],
  },
  {
    name: "Set Design",
    artists: [
      {
        name: "Christopher Amaro",
        to: "https://www.houseoforange.nl/artist/christopher-amaro",
      },
      {
        name: "Gwendolyn Witkin",
        to: "https://www.houseoforange.nl/artist/gwendolyn-witkin",
      },
      {
        name: "Jorien Kemerink",
        to: "https://www.houseoforange.nl/artist/jorien-kemerink",
      },
      {
        name: "Stef Bakker",
        to: "https://www.houseoforange.nl/artist/stef-bakker",
      },
    ],
  },
  {
    name: "Fashion Shows",
    artists: [
      {
        name: "Bas + Nathalie / Frontrowbackstage",
        to: "https://www.houseoforange.nl/artist/bas-nathalie-frontrowbackstage",
      },
    ],
  },
  {
    name: "Talent",
    artists: [
      {
        name: "Jan Hürxkens",
        to: "https://www.houseoforange.nl/artist/jan-hurxkens",
      },
      {
        name: "Nur Ozkan",
        to: "https://www.houseoforange.nl/artist/nur-ozkan",
      },
      {
        name: "Phoebe Vos",
        to: "https://www.houseoforange.nl/artist/phoebe-vos",
      },
      {
        name: "Sammy Does",
        to: "https://www.houseoforange.nl/artist/sammy-does",
      },
      {
        name: "Victoria Osborn",
        to: "https://www.houseoforange.nl/artist/victoria-osborn",
      },
    ],
  },
]
