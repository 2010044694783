import React from "react"

class IAm extends React.Component {
  state = this.getInitialState()
  randomKey(obj) {
    var keys = Object.keys(obj)
    // problem: chance is a lot greater you'll see an item of a small items list a lot more!! don't want that!
    // so first make an array of all items within the list to determine which to pick
    var key_arr = []
    for (var k = 0; k < keys.length; k++) {
      var items = obj[keys[k]]
      for (var i = 0; i < items.length; i++) {
        key_arr[key_arr.length] = k
      }
    }
    var random_key = (key_arr.length * Math.random()) << 0
    return keys[key_arr[random_key]]
  }
  randomItem(obj, key) {
    var items = obj[key]
    return items[(items.length * Math.random()) << 0]
  }
  getInitialState() {
    return this.pick(true)
  }
  componentDidMount() {
    var self = this
    self.i_am_interval = setInterval(() => self.setPick(), self.props.ms)
  }
  componentWillUnmount() {
    clearInterval(this.i_am_interval)
  }
  pick(init) {
    var data = this.props.i_am_data
    var keys = Object.keys(data)
    var random_key = this.randomKey(data)
    var random_item = this.randomItem(data, random_key)
    var key_color = this.randomColor()
    var item_color = this.randomColor()
    //
    // only change color when values (key, item) have changed
    if (!init) {
      if (this.state.random_key == random_key) {
        key_color = this.state.key_color
      }
    }
    //
    return {
      random_key: random_key,
      random_item: random_item,
      key_color: key_color,
      item_color: item_color
    }
  }
  setPick() {
    this.setState(this.pick())
  }
  randomIntensity() {
    return Math.round(Math.random() * 256)
  }
  randomColor() {
    return {
      r: this.randomIntensity(),
      g: this.randomIntensity(),
      b: this.randomIntensity()
    }
  }
  render() {
    var key_color = this.state.key_color
    var item_color = this.state.item_color
    var keyStyle = {
      color: "rgb(" + key_color.r + "," + key_color.g + "," + key_color.b + ")"
    }
    var itemStyle = {
      color:
        "rgb(" + item_color.r + "," + item_color.g + "," + item_color.b + ")"
    }
    return (
      <div className="i_am">
        <strong>{this.props.i}</strong>{" "}
        <span style={keyStyle}>{this.state.random_key}</span>{" "}
        <span style={itemStyle}>{this.state.random_item}</span>
      </div>
    )
  }
}

export default IAm

/*
var i_am_data = {
'loves' : [
    'bananas',
    'reactjs',
    'monique',
    'his kids',
    'clean energy'
    ],
'hates' : [
    'judgements',
    'war',
    'whatever',
    'shell'
    ]
};
*/

// <iAm i_am_data={i_am_data} name="Rudin Swagerman" ms={600}/>
