import React, { Component, Fragment } from "react"
import { useAllAlbums } from "../albumsQuery"
import projects_data from "../projects-data"
import TheHeader from "./header"
import ThumbsContainer from "./thumbscontainer"
import ScrollHandler from "smooth-scroll-handler"
import fnc_scrollto from "../utilities/smoothScroll"

const Home = (props) => {
  const [show_archive, set_show_archive] = React.useState(false)

  const { albums, tags } = useAllAlbums()
  console.log(albums)

  const { children } = props
  var top = window.scrollY
  var projects = []

  for (var i = 0; i < projects_data.length; i++) {
    if (typeof projects_data[i].hidden == "undefined") {
      if (
        show_archive ||
        (!show_archive && typeof projects_data[i].archived == "undefined")
      ) {
        projects.push(projects_data[i])
      }
    }
  }
  const details = {
    slug: "rudin",
    content: [
      { paragraph: "Rudin Swagerman, contact me at post@rudinswagerman.nl" },
    ],
  }

  return (
    <Fragment>
      <TheHeader />
      <ThumbsContainer
        projects={projects}
        showArchive={() => {
          fnc_scrollto(
            window.scrollY + Math.round((window.innerHeight / 4) * 2)
          )
          set_show_archive(!show_archive)
        }}
      />
      {children}
    </Fragment>
  )
}

export default Home
