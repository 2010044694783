/* http://www.htmlescape.net/htmlescape_tool.html */
/*     inactive: true, */
const Projects = [
  {
    title: "Kraaijvanger Architects",
    slug: "kraaijvanger",
    excerpt: "Website for architecture firm Kraaijvanger",
    background_color: "#EEEEEE",
    content: [
      { header: "Kraaijvanger Architects" },
      {
        paragraph:
          "I worked closely with designer Paul Swagerman on the website for Kraaijvanger Architects. Together, we worked to bring the design vision to life, creating a seamless web experience. Emphasizing clean aesthetics and intuitive navigation, the site highlights the architectural firm's innovative projects while ensuring optimal performance across all devices.",
      },
      {
        paragraph:
          "This website is built with Remix.run, and its contents are managed through Sanity.io.",
      },
      {
        link: "https://www.paulswagerman.com/",
        title: "Paul Swagerman",
        prepend: "Design:",
      },
      {
        link: "http://www.kraaijvanger.nl/",
        title: "www.kraaijvanger.nl",
        prepend: "Visit the website at:",
      },
      {
        image: "images/kraaijvanger-1.jpg",
        aspect_ratio: 2130 / 1710,
        more_space: true,
      },
      {
        image: "images/kraaijvanger-3.jpg",
        aspect_ratio: 2130 / 1710,
        more_space: true,
      },
      {
        image: "images/kraaijvanger-2.jpg",
        aspect_ratio: 2130 / 1710,
        more_space: true,
      },
    ],
    thumbnail: "kraaijvanger",
    aspect_ratio: 1966 / 1290,
  },
  {
    title: "Aectual",
    slug: "aectual",
    excerpt:
      "Web-application. Aectual enables designers and companies to realize made-to-measure designs at any scale in any building.",
    content: [
      { header: "Aectual" },
      {
        paragraph:
          "Aectual enables designers and companies to realize made-to-measure designs at any scale in any building. The Aectual website has a full featured platform integrated where you can manage and customize your 3D products.",
      },
      {
        link: "http://www.aectual.com/",
        title: "www.aectual.com",
        prepend: "Visit website:",
      },
      {
        image: "images/aectual-home.jpg",
        aspect_ratio: 2876 / 1628,
        more_space: true,
      },
      {
        image: "images/aectual-home-launchpad.jpg",
        aspect_ratio: 2874 / 1634,
        more_space: true,
      },
      {
        image: "images/aectual-collection.jpg",
        aspect_ratio: 2872 / 1630,
        more_space: true,
      },
      {
        image: "images/aectual-dashboard-projects.jpg",
        aspect_ratio: 2876 / 1642,
        more_space: true,
      },
      {
        image: "images/aectual-editor.jpg",
        aspect_ratio: 2872 / 1638,
        more_space: true,
      },
      {
        link: "http://www.aectual.com/",
        title: "www.aectual.com",
      },
    ],
    thumbnail: "aectual",
    aspect_ratio: 1500 / 1165,
    background_color: "rgb(218,218,218)",
  },
  {
    title: "Daniël Bouquet",
    slug: "danielbouquet",
    excerpt: "Portfolio website for cinematographer / photographer",
    content: [
      { header: "Daniël Bouquet" },
      {
        paragraph:
          "Premium custom tailored portfolio website for cinematographer / photographer Daniël Bouquet featuring characteristic carousels showing a random selection of projects.",
      },
      {
        paragraph:
          "This website is built with ReactJS, and its contents are managed through Whitespace.",
      },
      {
        link: "https://remoteforms.xyz/",
        title: "Ivo Toplak • Remote Forms",
        prepend: "Design:",
      },
      {
        link: "http://www.danielbouquet.com/",
        title: "www.danielbouquet.com",
        prepend: "Visit website:",
      },
      {
        image: "images/danielbouquet.jpg",
        aspect_ratio: 2659 / 1577,
        more_space: true,
      },
    ],
    thumbnail: "danielbouquet",
    aspect_ratio: 1878 / 924,
  },
  {
    title: "Whitespace",
    slug: "whitespace",
    excerpt:
      "Create a refined website for your photography. Signup at whitespace.app",
    content: [
      { header: "Whitespace" },
      {
        paragraph:
          "Every series of photos has its own optimal way in which the story can be conveyed, be it as a set of singles, a grid like overview, a cloud of images, etcetera. Whitespace explores and develops new ways of displaying photography on the web. We don't aim to offer one solution that fits all, but instead focus on the different and very specific ways in which you can present your photographic work. Embrace all the great possibilities screens have!",
      },
      {
        link: "https://whitespace.photos",
        title: "whitespace.photos",
        prepend: "For more info visit:",
      },
      {
        image: "images/whitespace-choose-design.jpg",
        aspect_ratio: 2880 / 1800,
      },
    ],
    thumbnail: "whitespace2",
    aspect_ratio: 2217 / 1554,
  },
  {
    title: "Sounds Like Touch",
    slug: "soundsliketouch",
    excerpt: "Interactive audiovisual piece",
    content: [
      { header: "Sounds Like Touch" },
      {
        paragraph:
          "Sounds Like Touch is a platform and springboard for artists and creators to experiment with physical forms and ways of interacting with technology. Together with Quinten Swagerman I developed this interactive blob which reacts to your mouse position by varying shape, pulsation and adjusting the sound accordingly. Sounds by Dianne Verdonk.",
      },
      {
        link: "https://www.soundsliketouch.com",
        title: "www.soundsliketouch.com",
        prepend: "Touch it:",
      },
      {
        image: "images/sounds-like-touch-01.jpg",
        aspect_ratio: 2656 / 1529,
        more_space: true,
      },
      {
        image: "images/sounds-like-touch-02.jpg",
        aspect_ratio: 2656 / 1540,
        more_space: true,
      },
    ],
    thumbnail: "sounds-like-touch",
    aspect_ratio: 1155 / 986,
    background_color: "rgb(230,230,230)",
  },
  {
    title: "House Of Orange",
    background_color: "#EFEFEF",
    slug: "houseoforange",
    excerpt:
      "Brandnew website for House Of Orange. An extensive archive of all artists' work",
    content: [
      { header: "House Of Orange" },
      {
        paragraph:
          "House of Orange serves the international fashion industry, from high end to cutting edge, with its top talent league of 50 stylists, hair and makeup artists and an ambitious production team for projects running from catwalk shows to campaigns.",
      },
      {
        paragraph:
          "This website is built with ReactJS, and its contents (7000 images+) are managed through Whitespace.",
      },
      {
        link: "http://www.theremoteforms.com/",
        title: "Ivo Toplak • Remote Forms",
        prepend: "Design:",
      },
      {
        link: "http://www.houseoforange.nl/",
        title: "www.houseoforange.nl",
        prepend: "Visit website:",
      },
      {
        image: "images/hoo1.jpg",
        aspect_ratio: 2880 / 1800,
        more_space: true,
      },
      {
        image: "images/hoo2.jpg",
        aspect_ratio: 2880 / 1800,
        more_space: true,
      },
      {
        image: "images/hoo3.jpg",
        aspect_ratio: 2880 / 1800,
        more_space: true,
      },
      {
        link: "http://www.houseoforange.nl/",
        title: "www.houseoforange.nl",
      },
    ],
    thumbnail: "houseoforange-artists",
    aspect_ratio: 2440 / 1488,
  },
  {
    title: "Dashboard for the Beeldenfabriek",
    background_color: "rgb(230,230,230)",
    slug: "dashboard",
    excerpt:
      "A tool to involve the customer in the process of creating perfect 3D visualizations.",
    content: [
      { header: "Client Dashboard" },
      {
        paragraph: `At the Beeldenfabriek, a creative team of 42 employees produces renderings. The team consists of architects, interior stylists and 3D artists who constantly push the boundaries of technology.`,
      },
      {
        paragraph:
          "With this tool the customer will be involved in the process of creating perfect 3D visualizations. Choose the preferred angle, give feedback on the lighting, the furniture, etc.",
      },
      {
        link: "https://www.beeldenfabriek.nl/",
        title: "Beeldenfabriek",
        prepend: "For:",
      },
      {
        image: "images/beeldenfabriek0.jpg",
        aspect_ratio: 1920 / 1080,
        more_space: true,
      },
      {
        image: "images/beeldenfabriek1.jpg",
        aspect_ratio: 1920 / 1080,
        more_space: true,
      },
    ],
    thumbnail: "bf-dashboard-steps",
    aspect_ratio: 1668 / 1004,
  },
  {
    title: "Huiswerkers",
    background_color: "#EFEFEF",
    slug: "huiswerkers",
    excerpt:
      "Small, Big, Many, Long, Outside, Smart, Playful, Temporary, Mobile, Sustainable, Fast, Colors, Detail, For Sale, Production, CNC, House, Work",
    content: [
      { header: "Huiswerkers" },
      {
        paragraph:
          "Two designers with a love for making work on a variety of projects, often consisting of different elements: Large, Small, Playful, Smart, Temporary. Preferably they do everything from A to Z.",
      },
      {
        paragraph:
          "This website is built with ReactJS, and its contents are managed through Whitespace.",
      },
      {
        link: "http://www.paulswagerman.com/",
        title: "Paul Swagerman",
        prepend: "Design:",
      },
      {
        link: "http://www.huiswerkers.nl/",
        title: "www.huiswerkers.nl",
        prepend: "Visit website:",
      },
      {
        image: "images/huiswerkers1.jpg",
        aspect_ratio: 2880 / 1800,
        more_space: true,
      },
      {
        image: "images/huiswerkers2.jpg",
        aspect_ratio: 2880 / 1800,
        more_space: true,
      },
      {
        image: "images/huiswerkers3.jpg",
        aspect_ratio: 2880 / 1800,
        more_space: true,
      },
      {
        image: "images/huiswerkers4.jpg",
        aspect_ratio: 2880 / 1800,
        more_space: true,
      },
      {
        link: "http://www.huiswerkers.nl/",
        title: "www.huiswerkers.nl",
      },
    ],
    thumbnail: "huiswerkers2",
    aspect_ratio: 2048 / 1536,
  },
  {
    title: "Multiplatform app with cranes and boats",
    slug: "360",
    excerpt:
      "Sales application for a manufacturer of equipment for the maritime industries, showcasing the companies products.",
    content: [
      { header: "Sales application" },
      {
        video: "https://vimeo.com/180305382",
        aspect_ratio: 16 / 9,
        white: true,
      },
      {
        paragraph:
          "Sales application for a manufacturer of equipment for the maritime industries, showcasing the companies products. The app can be installed on both desktop (Mac, Windows) and tablet (iOS, Android).",
      },
      {
        paragraph:
          "Technologies used: ReactJS, Webpack, Apache Cordova, NW.JS, Xcode, Android Studio, GIT",
      },
      {
        link: "http://www.beeldenfabriek.nl/illustratie-en-animatie/ons-werk",
        title: "De Beeldenfabriek, Rotterdam",
        prepend: "Design + 3D visualizations:",
      },
    ],
    thumbnail: "360",
    aspect_ratio: 2012 / 1226,
  },
  {
    title: "Viewbook.com",
    excerpt:
      "I co-founded viewbook.com. An online service for making beautiful and elegant portfolio websites for photographers. From 2009 to 2015 I have fulfilled many roles: from programmer to interaction-, motion- and interface design, prototyping, project lead, writing specs, guarding quality.",
    slug: "viewbook",
    content: [
      { header: "Viewbook.com" },
      {
        video: "https://vimeo.com/119964901",
        aspect_ratio: 16 / 9,
        white: true,
      },
      {
        paragraph:
          "I co-founded viewbook.com. Viewbook offers professional photographers a way to easily design and maintain their portfolio website. The emphasis is to offer simple, clean, well designed websites.<br/>Viewbook is an independent company, now serving thousands of users world wide.",
      },
      {
        paragraph:
          "Over the years I have fulfilled many roles: from programmer to interaction-, motion- and interface design, prototyping, project lead, writing specs, guarding quality.",
      },
      {
        paragraph:
          "I'm specialised in dealing with all questions about image display. From the number of pixels to serve to the most elegant ratio to show the image within a page on a PC, tablet or on mobile.",
      },
      {
        paragraph:
          "Below you'll find a video showcasing our quest in developing the next generation website builder:",
      },
      {
        video: "https://vimeo.com/128779766",
        aspect_ratio: 16 / 9,
        white: true,
        small: true,
      },
      {
        link: "http://www.viewbook.com",
        title: "www.viewbook.com",
        prepend: "visit:",
      },
    ],
    thumbnail: "viewbook",
    aspect_ratio: 2634 / 1476,
  },
  {
    title: "BananAlbum",
    slug: "bananalbum",
    excerpt:
      "In 2003 I started exploring new ways of presenting photography on the web. I created BananAlbum. The first scalable-gallery at that time, showing your images optimal on both small and big screens.",
    content: [
      { header: "BananAlbum" },
      {
        iframe: "http://www.bananalbum.com/albums/Going-places-2/",
        aspect_ratio: 1304 / 732,
        white: true,
        flash: true,
        fallback: {
          video: "https://vimeo.com/129450557",
          aspect_ratio: 1304 / 732,
        },
      },
      {
        paragraph:
          "In 2003, coming home from a trip to Venice with a huge selection of photos, I found out there was no beautiful and simple way to show them on the web. All existing web galleries at that time were very over- or underdesigned.",
      },
      {
        paragraph:
          "I decided to create a web gallery myself, with smooth transitions, a nicely scrolling thumbnail strip, scaling to have it fit all displays, and with preloading functionality, to minimize unnecessarily waiting.",
      },
      {
        paragraph:
          "BananAlbum was built with Actionscript/Flash, since at the time that was the only way to make sure images could be scaled in high quality, to have precise control over the loading and transitions and to include custom typefaces.",
      },
      {
        paragraph:
          "I started offering BananAlbum as a plugin for Lightroom, Picasa and as a skin for Jalbum. It soon became the most popular skin offered. &#x2026;and then there's Bananr where you can load your Flickr feed in a BananAlbum simply by entering your username.",
      },
      {
        link: "http://www.bananalbum.com",
        title: "www.bananalbum.com",
        prepend: "visit:",
      },
      {
        link: "http://www.bananr.com",
        title: "www.bananr.com",
        prepend: "visit:",
      },
      {
        image: "images/bananalbum/loadingdonut.jpg",
        aspect_ratio: 1249 / 999,
        small: true,
      },
      {
        image: "images/bananalbum/transition.jpg",
        aspect_ratio: 1255 / 1054,
        small: true,
      },
    ],
    thumbnail: "bananalbum-powerbook",
    aspect_ratio: 1500 / 1184,
  },
  {
    title: "De Werfklas",
    background_color: "#97cce2",
    slug: "werfklas",
    excerpt:
      "Being a web dev I of course had to create the website for my kids' school.",
    content: [
      { header: "De Werfklas" },
      {
        image: "images/werfklas-home.jpg",
        aspect_ratio: 1802 / 1170,
      },
      {
        link: "https://www.werfklas.nl/",
        title: "www.werfklas.nl",
        prepend: "Visit website:",
      },
      {
        paragraph: `"Vrije School de Werfklas" is a small-scale, innovative educational institution inspired by anthroposophy, catering to students aged 4 to 18 (primary and secondary education), located in Culemborg.`,
      },
      {
        paragraph: `At de Werfklas, the mission is to cultivate resilient, confident young adults who are prepared to navigate life's challenges with ease. With a strong emphasis on personalized attention and fostering independence, they create an environment where every student can flourish.`,
      },
    ],
    thumbnail: "werfklas-schooltekening2",
    aspect_ratio: 1741 / 1149,
  },
  {
    title: "Villa Zebra",
    slug: "villazebra",
    excerpt:
      "Villa Zebra is a museum and art laboratory specifically for kids based in Rotterdam. I did the coding for some games.",
    content: [
      { header: "Villa Zebra" },
      { video: "https://vimeo.com/131745122", aspect_ratio: 16 / 9 },
      {
        paragraph:
          "Villa Zebra is a museum and art laboratory specifically for kids based in Rotterdam. For every exhibition there's a digital counterpart where children can explore, play and wander. In the games I've coded you can build your avatar out of small things to be found in and around your home. In the monster factory you can construct your personal monster and e-mail it to your friends. An then there's the archive where you can stack and open boxes and where you can reveal photos and information of past exhibitions.",
      },
    ],
    thumbnail: "villazebra",
    aspect_ratio: 326 / 360,
  },
  {
    title: "Confetti",
    slug: "confetti",
    excerpt:
      "I like creating interactive & visual experiments while exploring new technologies.",
    content: [
      { header: "Confetti!" },
      { confetti: true, aspect_ratio: 16 / 9 },
      {
        paragraph:
          "I like creating interactive &amp; experiments while exploring new technologies. This confetti was made with Reactjs.",
      },
      {
        paragraph:
          "When hovering over an item the color changes, when hovering out it moves away. Click to have all items transition to a new position.",
      },
      {
        link: "http://www.rudinswagerman.nl/confetti",
        title: "rudinswagerman.nl/confetti",
      },
    ],
    thumbnail: "confetti",
    aspect_ratio: 1,
  },
  {
    title: "Pecha Kucha",
    slug: "pechakucha",
    excerpt:
      "Pecha Kucha about my early days exploring the world wide web, my experiments and fascinations.",
    content: [
      { header: "Pecha Kucha" },
      {
        video: "https://www.youtube.com/watch?v=MVWjRSlG_f0",
        aspect_ratio: 16 / 9,
      },
      {
        paragraph:
          "Me doing a Pecha Kucha about my early days exploring the world wide web, my experiments and fascinations.",
      },
      {
        link: "http://www.pechakuchadenhaag.nl/archief/pecha_kucha_7",
        title: "www.pechakuchadenhaag.nl/archief/pecha_kucha_7",
      },
    ],
    thumbnail: "pechakucha",
    aspect_ratio: 1640 / 1301,
  },
  {
    title: "",
    slug: "breakdance",
    content: [
      { header: "•" },
      {
        swf: "swf/breakdance/dotArray.swf",
        aspect_ratio: 3 / 2,
        white: true,
        fallback: {
          video: "https://vimeo.com/120149462",
          aspect_ratio: 16 / 9,
          white: true,
        },
      },
    ],
    thumbnail: "breakdance",
    aspect_ratio: 1 / 1,
  },
  {
    title: "Photography",
    slug: "photography",
    excerpt:
      "I have a preference for desolate places, unconsciously created compositions of buildings, objects, greenery, with people being mostly absent.",
    content: [
      { header: "Rudin Swagerman Photography" },
      {
        image: "images/rudinswagerman-photography.jpg",
        aspect_ratio: 1358 / 905,
      },
      {
        paragraph:
          "Ever since I was young I've loved making photographs and exploring printing techniques in the dark room. I have a preference for desolate places, unconsciously created compositions of buildings, objects, greenery, with people being mostly absent.",
      },
      {
        link: "https://rudin.photography",
        title: "rudin.photography",
        prepend: "visit my photography specific site",
      },
    ],
    thumbnail: "photography",
    aspect_ratio: 1358 / 905,
  },
  {
    title: "Viewbook PhotoStory Iris",
    slug: "iris",
    excerpt: "Growing and shrinking as if exposed to more or lesser light.",
    content: [
      { header: "Viewbook PhotoStory Iris" },
      { video: "https://vimeo.com/124161017", aspect_ratio: 16 / 9 },
      {
        paragraph:
          "Generative animation for the Viewbook PhotoStory competition site of 2009. It resembles an iris trying to focus and with an opening growing and shrinking as if exposed to more or lesser light.",
      },
      {
        link: "https://web.archive.org/web/20130430221338/http://www.viewbookphotostory.com/category/2009/",
        title: "viewbookphotostory.com/2009",
        prepend: "visit:",
      },
    ],
    thumbnail: "iris",
    aspect_ratio: 1,
  },
  {
    title: "Archive",
    slug: "archive",
    excerpt: "Reveal all archived items...",
    content: [
      { header: "Archive, soon..." },
      { image: "thumbnails/Rudin-Swagerman-HD-City.jpg", aspect_ratio: 3 / 2 },
    ],
    thumbnail: "Rudin-Swagerman-HD-City",
    aspect_ratio: 3 / 2,
  },
  {
    archived: true,
    title: "404",
    slug: "404",
    excerpt: "An associative stream of images",
    content: [
      { header: "viernulvier.nl" },
      /*
      {
        iframe: "http://www.viernulvier.nl",
        aspect_ratio: 1280 / 800,
        white: true
      },
      */
      {
        paragraph:
          "404 (viernulvier.nl) is a platform from me and some buddys of mine!<br/>Currently on display is this associative stream of images.<br/>We can react on the images each of us post by e-mailing our response.<br/>The longer the image is being presented, the smaller it gets!",
      },
      {
        link: "http://www.viernulvier.nl",
        title: "www.viernulvier.nl",
        prepend: "Visit website:",
      },
    ],
    thumbnail: "viernulvier2",
    aspect_ratio: 975 / 647,
  },
  {
    archived: true,
    title: "Rutger Vos",
    slug: "rutgervos",
    excerpt: "Website for Graphic Designer Rutger Vos.",
    content: [
      { header: "Rutger Vos" },
      { image: "thumbnails/Rutger-Vos.jpg", aspect_ratio: 1200 / 800 },
      {
        paragraph:
          "Studio Rutger Vos is a graphic design studio based in Amsterdam. Studio Rutger Vos uses simple and explicit design solutions.",
      },
      {
        paragraph:
          "Technologies used for this website: ReactJS, JavaScript, html, css, with Viewbook for managing the content.",
      },
      // {
      //   link: "http://www.rutgervos.nl/",
      //   title: "www.rutgervos.nl",
      //   prepend: "Visit website:",
      // },
    ],
    thumbnail: "Rutger-Vos",
    aspect_ratio: 1200 / 800,
  },
  {
    archived: true,
    title: "Van A naar F",
    slug: "vananaarf",
    excerpt: "A broad spectrum of references for bicycle infrastructure.",
    content: [
      { header: "Van A naar F" },
      { video: "https://vimeo.com/122965281", aspect_ratio: 1540 / 920 },
      {
        paragraph:
          "&#x2018;Van A naar F&#x2019; is een pool met een breed spectrum aan voorbeelden en referenties voor snelle fietsinfrastructuur. Deze voorbeeldprojecten dienen ter inspiratie voor onder meer verkeerskundigen, ontwerpers en beleidsmakers die aan de lat staan bij het ontwerpen van snelle fietsroutes.",
      },
      {
        link: "http://www.vananaarf.nl/",
        title: "www.vananaarf.nl",
        prepend: "Visit website:",
      },
    ],
    thumbnail: "vananaarf",
    aspect_ratio: 2683 / 2012,
  },
  {
    archived: true,
    title: "Artgineering",
    slug: "artgineering",
    excerpt:
      "Website for Artgineering: office for research and design operating at the intersection of urban planning and infrastructure.",
    content: [
      { header: "Artgineering" },
      { video: "https://vimeo.com/131762435", aspect_ratio: 1540 / 920 },
      {
        paragraph:
          "Website for Artgineering: office for research and design operating at the intersection of urban planning and infrastructure. The images and animations of the projects are presented as a stack of semi-transparent layers, and can be dragged around.",
      },
      {
        link: "http://www.artgineering.nl/",
        title: "www.artgineering.nl",
        prepend: "Visit website:",
      },
    ],
    thumbnail: "artgineering2",
    aspect_ratio: 620 / 620,
  },
  {
    archived: true,
    title: "Remy Veenhuizen",
    slug: "remyveenhuizen",
    excerpt:
      "Website for design duo Tejo Remy &amp; Ren&eacute; Veenhuizen featuring an infinitely scrolling panorama of their workspace.",
    content: [
      { header: "Remy Veenhuizen" },
      { video: "https://vimeo.com/128984545", aspect_ratio: 1098 / 618 },
      {
        paragraph:
          "The website for design duo Tejo Remy &amp; Ren&eacute; Veenhuizen (archived) features an infinitely scrolling panorama of their workspace. The panorama is brutally being constructed and deconstructed as you move around. In this space you'll find their most important portfolio pieces highlighted in yellow.",
      },
      {
        paragraph:
          "At the back-end of the site is a data-set specifying the positions of all images composing the panorama in real-time. As you move your mouse you can vary speed or change direction, at any position of the scene.",
      },
      {
        link: "http://rudinswagerman.nl/archive/www.remyveenhuizen.nl/",
        title: "archive/www.remyveenhuizen.nl",
        prepend: "Visit website (archived):",
      },
      {
        link: "http://www.accuontwerpers.nl/",
        title: "accuontwerpers.nl",
        prepend: "design:",
      },
      {
        link: "http://www.rudinswagerman.nl/",
        title: "rudinswagerman.nl",
        prepend: "interaction design & development:",
      },
    ],
    thumbnail: "remyveenhuizen",
    aspect_ratio: 1111 / 593,
  },
  {
    archived: true,
    title: "The Frik Collection",
    slug: "frik",
    excerpt: "Rummage around, delve into the visual world of Helen Frik!",
    content: [
      { header: "The Frik Collection" },
      { video: "https://vimeo.com/122726978", aspect_ratio: 1540 / 920 },
      {
        paragraph:
          "This site welcomes you to rummage around, delving into the visual world of Helen Frik. The taxonomy of the Frik Library facilitates a non-linear, curiosity led journey, but it is still possible to sit down and study in a responsible fashion.",
      },
      {
        link: "https://www.helenfrik.nl/",
        title: "helenfrik.nl",
        prepend: "Visit website:",
      },
      {
        link: "https://www.paulswagerman.com/",
        title: "paulswagerman.nl",
        prepend: "design:",
      },
      {
        link: "https://www.rudinswagerman.nl/",
        title: "rudinswagerman.nl",
        prepend: "development:",
      },
    ],
    thumbnail: "frik",
    aspect_ratio: 3339 / 1615,
  },
  {
    archived: true,
    title: "Architecture In Development",
    slug: "aid",
    excerpt:
      "In this short film, we're introducing Buckminster Fuller, an engineer, inventor and great visionary.",
    content: [
      { header: "Architecture In Development" },
      { video: "https://vimeo.com/127617713", aspect_ratio: 16 / 9 },
      {
        paragraph:
          "Together with Quinten Swagerman I made this short film to promote Architecture In Development. A platform with user-generated knowledge that aims to to re-connect sustainable development to architecture.",
      },
      {
        paragraph:
          "In this short film, we're introducing Buckminster Fuller, an engineer, inventor and great visionary. In the early 60's, far before the invention of the personal computer and the Internet, he proposed a system for worldwide collaboration, with a comparable goal to that of the Architecture In Development initiative.",
      },
      {
        link: "http://www.architectureindevelopment.org",
        title: "architectureindevelopment.org",
      },
    ],
    thumbnail: "aid",
    aspect_ratio: 3 / 2,
  },
  {
    archived: true,
    title: "Theo Poel",
    slug: "theopoel",
    excerpt:
      "Website for artist Theo Poel. With his paintings of faces he's showing us the bright side of life.",
    content: [
      { header: "Theo Poel" },
      {
        iframe: "http://www.rudinswagerman.nl/archive/www.theopoel.nl/new.html",
        aspect_ratio: 1280 / 720,
        white: true,
        flash: true,
        fallback: {
          video: "https://vimeo.com/129879481",
          aspect_ratio: 1280 / 720,
        },
      },
      {
        paragraph:
          "Website for artist Theo Poel. A lot of faces floating in space, growing and shrinking.",
      },
      {
        link: "http://www.rudinswagerman.nl/archive/www.theopoel.nl",
        title: "archive/www.theopoel.nl",
        prepend: "Visit website (archived):",
      },
    ],
    thumbnail: "theopoel",
    aspect_ratio: 600 / 450,
  },
  {
    archived: true,
    title: "RAUM",
    slug: "raum",
    excerpt:
      "Naming, fa&ccedil;ade, routing and mini exhibition space for RAUM, a building that houses studios of people from different disciplines within the cultural sector.",
    date: "",
    content: [
      { header: "RAUM" },
      {
        image: "images/raum-façade.jpg",
        aspect_ratio: 3 / 2,
      },
      {
        paragraph:
          "Naming, fa&ccedil;ade, routing and mini exhibition space for RAUM, a building that houses studios of people from different disciplines within the cultural sector.",
      },
      {
        link: "https://www.scuric.nl",
        title: "Monique Scuric",
        prepend: "together with",
      },
      {
        link: "https://www.paulswagerman.com/",
        title: "Paul Swagerman",
        prepend: "and",
      },
      {
        image: "images/raum-façade-in-progress.jpg",
        aspect_ratio: 3 / 2,
        small: true,
      },
      {
        image: "images/raum-staircase.jpg",
        aspect_ratio: 2 / 3,
        small: true,
      },
    ],
    thumbnail: "raum",
    aspect_ratio: 1109 / 1109,
  },
  {
    archived: true,
    title: "Serendipity",
    slug: "serendipity",
    excerpt:
      "A series of fortunate events occurred while I was filming the moving window across the road of my studio space.",
    content: [
      { header: "Serendipity" },
      { video: "https://vimeo.com/7838883", aspect_ratio: 16 / 9 },
      { paragraph: "A series of fortunate events." },
    ],
    thumbnail: "serendipity",
    aspect_ratio: 16 / 9,
  },
  {
    archived: true,
    title: "Gradient Couple",
    slug: "gradient",
    excerpt: "Two gradient planes receiving new colors at a random interval.",
    content: [
      { header: "Gradient Couple" },
      { video: "https://vimeo.com/120137843", aspect_ratio: 16 / 9 },
      {
        paragraph:
          "A screen consisting of two gradient planes receiving new colors at a random interval. The colors are derived from random positions of a photograph of my lovely boy.",
      },
    ],
    thumbnail: "gradient",
    aspect_ratio: 1,
  },
  {
    archived: true,
    title: "Hyperbody Muscle Tower II",
    slug: "muscletower",
    excerpt:
      "The interactive &amp; kinetic MUSCLE Tower II reacts to its environment. It consists of aluminium tubes, connected to each other and the FESTO-Muscles by iron joint. The FESTO-Muscles are controlled by a VirTools Script, which is connected to movement sensors.",
    date: "2004",
    content: [
      { header: "Hyperbody Muscle Tower II" },
      { video: "https://vimeo.com/122797542", aspect_ratio: 16 / 9 },
      {
        paragraph:
          "The interactive &amp; kinetic MUSCLE Tower II reacts to its environment. It consists of aluminium tubes, connected to each other and the FESTO-Muscles by iron joint. The FESTO-Muscles are controlled by a VirTools Script, which is connected to movement sensors.",
      },
      {
        paragraph:
          "I was mainly responsible for the brain of this thing, its behaviour. What, when to do, based on the sensory input.",
      },
      {
        paragraph:
          "The MUSCLE Tower II is an educational and research project developed by The Hyperbody Research Group at Delft University of Technology. It was designed and built by BSc 6 students within nine weeks.",
      },
      {
        image: "images/hyperbody-muscle-tower-ii.jpg",
        aspect_ratio: 960 / 1280,
        small: true,
      },
    ],
    thumbnail: "muscletower",
    aspect_ratio: 1333 / 1000,
  },
  {
    archived: true,
    title: "Random Tower",
    slug: "randomtower",
    excerpt:
      "This tower consists of triangles stacked on top of each other. It's fully parametric, so by editing one single number you can increase the number of segments and create an ever higher tower. This experiment is part of my research to the movements of the Hyperbody Muscle Tower II.",
    content: [
      { header: "Random Tower" },
      {
        swf: "swf/randomtower/random.swf",
        aspect_ratio: 16 / 9,
        white: true,
        fallback: {
          video: "https://vimeo.com/130035499",
          aspect_ratio: 16 / 9,
          white: true,
        },
      },
      {
        paragraph:
          "This tower consists of triangles stacked on top of each other. It's fully parametric, so by editing one single number you can increase the number of segments and create an ever higher tower. This experiment is part of my research to the movements of the Hyperbody Muscle Tower II.",
      },
    ],
    thumbnail: "randomtower2",
    aspect_ratio: 5 / 4,
  },
  {
    archived: true,
    title: "",
    slug: "move",
    content: [
      { header: "•" },
      { video: "https://vimeo.com/120139467", aspect_ratio: 16 / 9 },
    ],
    thumbnail: "move",
    aspect_ratio: 5 / 4,
  },
  {
    archived: true,
    title: "Nacht van de Hoop",
    slug: "nachtvandehoop",
    excerpt: "Stars floating in space.",
    year: "2011",
    content: [
      { header: "Nacht van de Hoop" },
      { video: "https://vimeo.com/130093887", aspect_ratio: 1540 / 920 },
      { paragraph: "Website for event." },
      {
        link: "http://rudinswagerman.nl/archive/www.nachtvandehoop.nl/",
        title: "archive/www.nachtvandehoop.nl",
        prepend: "Visit website (archived):",
      },
      {
        link: "http://www.v-annemarie.nl/",
        title: "v-annemarie.nl",
        prepend: "with:",
      },
    ],
    thumbnail: "nachtvandehoop",
    aspect_ratio: 3 / 2,
  },
  {
    archived: true,
    title: "Catastrophic Space",
    excerpt: "An audiotour through East Beirut.",
    slug: "catastrophicspace",
    year: "2007",
    content: [
      { header: "Catastrophic Space" },
      {
        iframe: "http://www.partizanpublik.nl/catastrophicspace/site.html",
        aspect_ratio: 4 / 3,
        flash: true,
        fallback: {
          video: "https://vimeo.com/130233253",
          aspect_ratio: 16 / 9,
        },
      },
      {
        paragraph:
          "Catastrophic Space: an audio tour through East Beirut by Tony Chakar.<br/>Voice-over #1: &#x2018;Catastrophic Space&#x2019; welcomes you to a small neighborhood just off Sassine Square. Wandering through the area, the voice of artist/architect/writer Tony Chakar will introduce ideas on the porous city and the notion of catastrophic space and time. During the tour, you can take in or browse through the reference library, that provides context in the realms of art, literature, topography and photography. Visitors to Beirut risk a trip solely into the spectacular. Apart from Martyr Square and the Corniche, most of the cities landmarks are places and buildings that refer to war and destruction. The Muhr and Holiday Inn towers, the iconic Barakat building, the remnants of the Green Line, the Sabra and Chatilla Memorial Site; many of the obvious entries penetrate the city from its most vulnerable, raw, dramatic site. The spectacular is the obvious. But there is much more to the city. This website proposes a number of new entries into the city of Beirut. Places and spaces of inductive urban myth, seductive memories and provoking thoughts. We welcome, first-time visitors and residents alike, to dig into Beirut. Beirut is a city with a rich and dramatic history and an amazingly prolific intellectual and cultural life; it is these assets that we use at Voice-Over Beirut to make new ancorages into the city. Tour by tour you dig into Beirut; not via the silent, impressive building, but though the stories and thoughts of extraordinary people.",
      },
    ],
    thumbnail: "catastrophicspace",
    aspect_ratio: 3 / 2,
  },
  {
    archived: true,
    title: "Freestyle Rotterdam Biking",
    slug: "freestylerotterdambiking",
    year: "2002",
    content: [
      { header: "Freestyle Rotterdam Biking" },
      {
        iframe: "http://dataprocessingcenter.org/404/freestylerotterdambiking/",
        aspect_ratio: 16 / 9,
        flash: true,
        fallback: {
          video: "https://vimeo.com/130103577",
          aspect_ratio: 16 / 9,
        },
      },
      {
        paragraph:
          "Choose either Remko or me and bike through the harbours of Rotterdam! Try to stay as close to the red line as possible to get the highest score!",
      },
      {
        link: "http://dataprocessingcenter.org/404/freestylerotterdambiking/",
        title: "404/freestylerotterdambiking",
        prepend: "play:",
      },
    ],
    thumbnail: "freestylerotterdambiking2",
    aspect_ratio: 16 / 9,
  },
  {
    archived: true,
    title: "RudinPX",
    slug: "rudinpx",
    year: "2002",
    excerpt: "Do you remember? How pixelfonts used to rock?!",
    content: [
      { header: "RudinPX" },
      { image: "images/rudinpx/rudinpx.jpg", aspect_ratio: 1357 / 686 },
      {
        link: "http://www.rudinswagerman.nl/download/rudinpx.zip",
        title: "rudinpx.zip",
        prepend: "download:",
      },
      {
        image: "images/rudinpx/20020208-154525-DCR-PC100.jpg",
        aspect_ratio: 640 / 480,
        small: true,
      },
      {
        image: "images/rudinpx/20020208-154551-DCR-PC100.jpg",
        aspect_ratio: 640 / 480,
        small: true,
      },
      {
        image: "images/rudinpx/20020208-154617-DCR-PC100.jpg",
        aspect_ratio: 640 / 480,
        small: true,
      },
      {
        image: "images/rudinpx/20020208-154822-DCR-PC100.jpg",
        aspect_ratio: 640 / 480,
        small: true,
      },
      {
        image: "images/rudinpx/20020208-154901-DCR-PC100.jpg",
        aspect_ratio: 640 / 480,
        small: true,
      },
      {
        image: "images/rudinpx/20020208-155009-DCR-PC100.jpg",
        aspect_ratio: 640 / 480,
        small: true,
      },
    ],
    thumbnail: "rudinpx3",
    aspect_ratio: 676 / 636,
  },
  {
    archived: true,
    title: "Atari404",
    slug: "atari",
    year: "2002",
    excerpt: "Based on the Classic Atari 8-bit character set.",
    content: [
      { header: "Atari404" },
      { image: "images/atari404/atari404.jpg", aspect_ratio: 1641 / 761 },
      { paragraph: "Based on the Classic Atari 8-bit character set." },
      { paragraph: "This font must be set at 8 point, or any multiple of 8." },
      { paragraph: "The font must be placed on a whole pixel." },
      { paragraph: "Do not use centered paragraph alignment." },
      {
        link: "http://www.rudinswagerman.nl/download/atari404.zip",
        title: "atari404.zip",
        prepend: "download:",
      },
    ],
    thumbnail: "atari404",
    aspect_ratio: 814 / 745,
  },
  {
    archived: true,
    title: "404 Freestyle Desk Chair Skating",
    slug: "404fdcs",
    excerpt: "",
    content: [
      { header: "" },
      {
        iframe: "http://www.rudinswagerman.nl/archive/404fdcs/",
        aspect_ratio: 16 / 10,
        flash: true,
        fallback: {
          video: "https://vimeo.com/131960296",
          aspect_ratio: 796 / 592,
        },
      },
    ],
    thumbnail: "404fdcs",
    aspect_ratio: 799 / 593,
  },
  {
    archived: true,
    title: "Vlieger & Vandam",
    slug: "vliegervandam",
    excerpt: "Website for Vlieger & Vandam, 2002",
    content: [
      { header: "Vlieger & Vandam" },
      {
        iframe: "http://www.rudinswagerman.nl/archive/www.vliegervandam.com/",
        aspect_ratio: 16 / 10,
        flash: true,
        fallback: {
          video: "https://vimeo.com/131871993",
          aspect_ratio: 1024 / 684,
        },
      },
    ],
    thumbnail: "vliegervandam",
    aspect_ratio: 740 / 516,
  },
  {
    archived: true,
    title: "Default Office",
    slug: "defaultoffice",
    excerpt: "Website for Default Office",
    content: [
      { header: "Default Office" },
      {
        paragraph:
          "Default Office was a communication design agency for both analogue and digital media, founded by my three brothers in Arnhem around the time of the dotcom bubble. I regularly joined them as a freelance developer, bringing my specialization in Flash to their digital projects.",
      },
      {
        paragraph:
          "The website had a playful introduction with a game of Pong. The agency's portfolio was cleverly hidden, encouraging users to explore and reveal it by opening two interactive cubes.",
      },
      {
        iframe: "http://www.rudinswagerman.nl/archive/www.default-office.nl/",
        aspect_ratio: 16 / 10,
        flash: true,
        fallback: {
          video: "https://vimeo.com/131959925",
          aspect_ratio: 1024 / 684,
          placeholder: "images/defaultoffice.jpg",
        },
      },
    ],
    thumbnail: "defaultoffice",
    aspect_ratio: 700 / 513,
  },
  {
    archived: true,
    title: "Ruskweb",
    slug: "ruskweb",
    excerpt: "My first website... Talkin' 1999 ;-)",
    content: [
      { header: "Ruskweb" },
      {
        iframe: "http://www.viernulvier.nl/ruskweb/",
        aspect_ratio: 16 / 9,
        flash: true,
        fallback: {
          video: "https://vimeo.com/132856622",
          aspect_ratio: 16 / 10,
        },
      },
    ],
    thumbnail: "ruskweb",
    aspect_ratio: 1817 / 1240,
  },
]

export default Projects
