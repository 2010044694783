import React, { useState } from "react"
import copy from "copy-to-clipboard"

function makeId() {
  var text = ""
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}

export default () => {
  const [id, setId] = useState(makeId())

  return (
    <div
      style={{ fontSize: "20em", lineHeight: "initial" }}
      onClick={() => {
        copy(id)
        window.alert("copied " + id)
        setId(makeId())
      }}
    >
      {id}
    </div>
  )
}
