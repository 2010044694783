import React from "react"

import ReactSWF from "react-swf"
import SWFPlayerVersion from "swf-player-version"

import Video from "./video"

import "./iframe_.css" // css

function Iframe(props) {
  var url = props.block_content.iframe

  var iframe_height =
    Math.round((100 * 100) / props.block_content.aspect_ratio) / 100

  var iframe_height_css = iframe_height + "%"
  var background_color =
    typeof props.block_content.white != "undefined" ? "#FFFFFF" : "#000000"

  var style = {
    paddingBottom: iframe_height_css,
    backgroundColor: background_color
  }

  if (
    SWFPlayerVersion.isSupported("10.0") ||
    typeof props.block_content.flash == "undefined"
  ) {
    return (
      <div className="iframe-container">
        <div className="iframe-content" style={style}>
          <iframe src={url} width="100%" height="100%" />
        </div>
      </div>
    )
  } else {
    return <Video block_content={props.block_content.fallback} />
  }
}

export default Iframe
