import { useQuery, gql } from "@apollo/client"

const albumsQuery = gql`
  query allAlbums($username: String!) {
    userByUsername(username: $username) {
      tags: albumTags {
        id
        name
      }
      albums(orderBy: POS_DESC, filter: { hidden: { equalTo: false } }) {
        id
        title
        slug
        description
        keyValues {
          id
          key {
            name
          }
          value
        }
        tags {
          id
          name
        }
        slides(orderBy: POS_ASC, filter: { hidden: { equalTo: false } }) {
          id
          order: pos
          file: image {
            id
            url
            secret
          }
          videoUrl
          video {
            id
            status
            secret
          }
          width
          height
          title
          description
          tags {
            id
            name
          }
          keyValues {
            id
            key {
              name
            }
            value
          }
        }
      }
    }
  }
`

const albumBySlugQuery = gql`
  query allAlbums($username: String!, $slug: String!) {
    userByUsername(username: $username) {
      albums(filter: { slug: { equalTo: $slug } }) {
        id
        title
        slug
        description
        keyValues {
          id
          key {
            name
          }
          value
        }
        tags {
          id
          name
        }
        slides(orderBy: POS_ASC, filter: { hidden: { equalTo: false } }) {
          id
          order: pos
          file: image {
            id
            url
            secret
          }
          videoUrl
          video {
            id
            status
            secret
          }
          width
          height
          title
          description
          tags {
            id
            name
          }
          keyValues {
            id
            key {
              name
            }
            value
          }
        }
      }
    }
  }
`

export const useAllAlbums = () => {
  const { data } = useQuery(albumsQuery, {
    variables: { username: "rudinwork" },
  })
  return {
    albums: data?.userByUsername?.albums,
    tags: data?.userByUsername?.tags,
    data,
  }
}

export const useAlbumBySlug = (slug) => {
  const { data } = useQuery(albumBySlugQuery, {
    variables: { username: "rudinwork", slug: slug },
  })
  return {
    album: data?.userByUsername?.albums?.[0],
  }
}
