var template = function (prepend, append, type, url) {
  switch (type) {
    case "youtube":
      return (
        prepend +
        '<iframe src="' +
        url +
        '?rel=0" width="100%" height="100%" frameborder="0" style="position:absolute;top:0;" allowfullscreen></iframe>' +
        append
      )
      break

    case "vimeo":
      return (
        prepend +
        '<iframe src="' +
        url +
        '?title=0&byline=0&portrait=0&background=1&autoPlay=1&loop=1" width="100%" height="100%" frameborder="0" style="position:absolute;top:0;" allowfullscreen></iframe>' +
        append
      )
      break
  }
}

var embed = function (link, aspect_ratio) {
  var pattern, match, position
  var patterns = [
    {
      regex: /youtu\.be\/([\w\-.]+)/,
      type: "youtube",
      url: "https://www.youtube.com/embed/%1",
    },
    {
      regex: /youtube\.com(.+)v=([^&]+)/,
      type: "youtube",
      url: "https://www.youtube.com/embed/%2",
    },
    {
      regex: /vimeo\.com\/([0-9]+)/,
      type: "vimeo",
      url: "https://player.vimeo.com/video/%1",
    },
    {
      regex: /vimeo\.com\/(.*)\/([0-9]+)/,
      type: "vimeo",
      url: "https://player.vimeo.com/video/%2",
    },
  ]

  var type = ""
  var url = ""
  var embed_html = ""
  var prepend = ""
  var append = ""

  if (typeof aspect_ratio != undefined) {
    var percent_ratio = Math.round((100 * 100) / aspect_ratio) / 100
    prepend =
      '<div style="padding-bottom:' +
      percent_ratio +
      '%;position:relative;width:100%;height:0;overflow:hidden;">'
    append = "</div>"
  }

  for (var i = 0; i < patterns.length; i++) {
    pattern = patterns[i]

    if ((match = link.match(pattern.regex))) {
      position = pattern.url.match(/%([0-9]+)/)[1]
      type = pattern.type
      url = pattern.url.replace(/%([0-9]+)/, match[position])
    }
  }

  return template(prepend, append, type, url)
}

// https://vimeo.com/123608929

/*
<div style="width:100%;"><div style="padding-bottom:56.25%;position:relative;width:100%;height:0;overflow:hidden;">
<iframe src="//player.vimeo.com/video/123608929?title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" style="position:absolute;top:0;" allowfullscreen>
</iframe></div></div>
*/

export default embed
