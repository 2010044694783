import React from "react"

import "./year_.css" // css

function Year(props) {
  if (typeof props.project_data.year != "undefined") {
    return <div className="year">year: {props.project_data.year}</div>
  } else {
    return null
  }
}

export default Year
