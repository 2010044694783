import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import { Router, Link, Redirect, Location, navigate } from "@reach/router"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import ThemeProvider from "./components/ThemeProvider"
import { Helmet } from "react-helmet"

import "./base.css"
import "./clearfix.css"
// const FullProjects = require('components/fullprojects');
// fullprojects: zorgen dat dat nog wel uit te renderen is! position in dat geval niet absoluut?! prop van maken?! overlayd = true ofzo?!
import Home from "./components/Home"
import Project from "./components/Project"

import Random from "./random"

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client"
import { useAllAlbums } from "./albumsQuery"

const client = new ApolloClient({
  uri: "https://api.whitespace.photos/graphql",
  cache: new InMemoryCache(),
})

const FadeTransitionRouter = (props) => (
  <Location>
    {({ location }) => (
      <TransitionGroup
        className="transition-group"
        style={{ position: "absolute", top: 0, left: 0, right: 0 }}
      >
        <CSSTransition key={location.key} classNames="fade" timeout={500}>
          {/* the only difference between a router animation and
              any other animation is that you have to pass the
              location to the router so the old screen renders
              the "old location" */}
          <Router
            location={location}
            className="router"
            primary={false}
            style={{ position: "absolute", left: 0, right: 0, top: 0 }}
          >
            {props.children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
)

const Empty = () => <div>EMPTY!!!</div>

function App() {
  const title = "Rudin ⚬ Aleatory Artificer"
  const description = `I create simple, elegant and intuitive interfaces for websites and (web-)apps, employing the latest developments in JavaScript – ReactJS, HTML and CSS. I’m an abstract thinker, writing clean and efficient code. Simultaneously, my visual and artistic background enables me to create well balanced, visually engaging, pixel-perfect interfaces. I work closely with designers to come to symbiotic solutions both in code and design.`

  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <div>
          <Fragment>
            <Helmet>
              <title>{title}</title>
              <meta name="og:title" content={title} />
              <meta name="description" content={description} />
              <meta name="og:description" content={description} />
            </Helmet>
            <div className="home">
              <Router>
                <Home path="/*" />
                <Random path="/random" />
              </Router>
            </div>
            <FadeTransitionRouter path=":slug">
              <Project path=":slug" />
            </FadeTransitionRouter>
          </Fragment>
        </div>
      </ThemeProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))
