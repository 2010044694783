import React from "react"
import IAm from "./iam/iam"

import "./header_.css"

import Profile from "./profile"

const TheHeader = (props) => {
  var profile_text =
    "Front end developer with a keen sense for details and refined interaction."
  var i_am_data = {
    is: [
      "a maker",
      "a programmer",
      "an entrepeneur",
      "a daddy of two",
      "a web developer",
      "a software developer",
    ],
    loves: [
      "reactjs",
      "photography",
      "pixels",
      "aspect ratios",
      "color",
      "new media",
      "whitespace",
      "design",
      "to play",
      "to experiment",
      "clean code",
      "map, filter, reduce",
      "react native",
      "javascript",
      "his kids",
      "the sun",
      "love",
    ],
    "co-founded": ["viewbook.com"],
    founded: ["Whitespace"],
    created: ["BananAlbum"],
    "is specialised in": [
      "pixels",
      "calculating aspect ratios",
      "image display",
      "responsiveness",
      "reactjs",
      "javascript",
      "breathing",
    ],
    minds: ["the details"],
  }
  return (
    <header>
      <IAm i_am_data={i_am_data} i="Rudin" ms={970} />
      <div className="contact">
        contact me at <a href="mailto:hello@rudin.work">hello@rudin.work</a>
        <span className="no-wrap">
          {" "}
          or <a href="tel:+31618819782">+31 (0)6 1881 9782</a>
        </span>
      </div>
    </header>
  )
  // <Profile text={profile_text}/>
}

export default TheHeader
