// functie om een beeld van het juiste formaat in te laden, via die ene Google proxy
// een basis is al te vinden in...

const sizes = [
  { size: 720, path: "" },
  { size: 960, path: "" },
  { size: 1280, path: "" },
  { size: 1340, path: "" }
]

const whatPixelRatio = function() {
  let px_ratio = window.devicePixelRatio ? window.devicePixelRatio : 1
  let screen_w = window.screen.width
  let screen_h = window.screen.height
  if (window.matchMedia) {
    if (
      window.matchMedia("(orientation: landscape)").matches &&
      screen_w < screen_h
    ) {
      screen_h = [screen_w, (screen_w = screen_h)][0]
    }
  }
  if (screen_w < window.innerWidth) {
    px_ratio = px_ratio * (screen_w / window.innerWidth)
  }
  return px_ratio
}

window.px_ratio = whatPixelRatio()

const findOutWhatSizeWillSuffice = function(
  image_dimensions,
  required_dimensions,
  sizes
) {
  // image_data contains 'sizes' array and width, height
  // canvas is an object conaining {width and height}
  // image_data includes file name, and available sizes
  // function returns size?... so this function should actually be called by
  // from this, find out what size will suit best and construct the url?
  // no?! simply return what size? constructImageUrl is another function.
  const px_ratio = window.px_ratio
  // const use_px_ratio = 1+(px_ratio-1)*0.8;
  const required_surface =
    required_dimensions.width *
    px_ratio *
    (required_dimensions.height * px_ratio)
  console.log(required_surface)
  // some factor: use_px_ratio = 1+(px_ratio-1)*0.8;
  // be a bit more loose?! slightly upscaling on a retina screen is not such a big a deal
  // loop through sizes and find out which suits best...
  for (let i = 0; i < sizes.length; i++) {
    let this_dimensions = fit(image_dimensions, {
      width: sizes[i].size,
      height: sizes[i].size
    })
    let this_surface = this_dimensions.width * this_dimensions.height
    if (this_surface >= required_surface || i === sizes.length - 1) {
      return {
        image_width: this_dimensions.width,
        image_height: this_dimensions.height,
        size_index: i
      }
      break
    }
  }
  // just the number?!
  // needed for preloading the proper image?
}

const fit = function(image, canvas) {
  const canvas_ratio = canvas.width / canvas.height
  const image_ratio = image.width / image.height
  if (canvas_ratio > image_ratio) {
    var resulting_width = Math.round(canvas.height * image_ratio)
    return { width: resulting_width, height: canvas.height }
  } else {
    var resulting_height = Math.round(canvas.width / image_ratio)
    return { width: canvas.width, height: resulting_height }
  }
}

export function whatSizeToServe(image_data, canvas) {
  const image_dimensions = {
    width: image_data.width,
    height: image_data.height
  }
  const required_dimensions = fit(image_dimensions, canvas)
  const { width, height } = required_dimensions
  const { image_width, image_height, size_index } = findOutWhatSizeWillSuffice(
    image_dimensions,
    required_dimensions,
    sizes
  )
  // niet hier... const image_url =
  // image url, dimensions, image_dimensions, size_index
  return { width, height, image_width, image_height, size_index }
}

// copied from whitespace app

const surfaces = [
  80 * 60,
  160 * 120,
  320 * 240,
  640 * 480,
  720 * 540,
  960 * 1280,
  1024 * 1420,
  1536 * 2048
]

// later ook compressie erbij: hidpi: hogere compressie
const optimalSurface = (canvas, surfaces, pixelRatio) => {
  console.log({ pixelRatio })
  const canvasSurface = canvas.width * pixelRatio * (canvas.height * pixelRatio)
  for (let i = 0; i < surfaces.length; i++) {
    // the first surface that's bigger, times a ratio, give me that one
    if (surfaces[i] > canvasSurface * 1.01) {
      console.log({ i }, surfaces[i])

      return surfaces[i]
    }
  }
  // last resort
  console.log("last resort")
  return surfaces[surfaces.length - 1]
  // return dimensions: width and height
}

const dimensionsToServe = canvas => {
  const pixelRatio = getDevicePixelRatio()
  const surface = optimalSurface(canvas, surfaces, pixelRatio)

  const imageRatio = canvas.width / canvas.height
  let width = imageRatio * Math.sqrt(surface / imageRatio)
  const height = Math.round(width / imageRatio)
  width = Math.round(width)
  return {
    width,
    height
  }
}

export default dimensionsToServe

const getDevicePixelRatio = () => {
  if (window.devicePixelRatio) {
    return window.devicePixelRatio
  } else {
    return 1
  }
}

export const getPixelRatio = () => {
  var pixelRatio = getDevicePixelRatio()
  var screenWidth = window.screen.width
  var screenHeight = window.screen.height
  if (window.matchMedia) {
    if (
      window.matchMedia("(orientation: landscape)").matches &&
      screenWidth < screenHeight
    ) {
      screenHeight = [screenWidth, (screenWidth = screenHeight)][0]
    }
  }
  if (screenWidth < window.innerWidth) {
    pixelRatio = pixelRatio * (screenWidth / window.innerWidth)
  }
  // it's fine to load a 1024 image in a canvas of 520 pixels on a retina screen you see:
  // on a hidpi screen a slightly smaller image is acceptable: (losse functie van maken, rite!)
  pixelRatio = 1 + (pixelRatio - 1) * 0.75
  return pixelRatio
}

// jalbum specific:
export function imageURL(image_data, size_index, path) {
  return `${path}${image_data.sizes[size_index].path}${image_data.image}`
}
