import React from "react"
import ReactSWF from "react-swf"
import SWFPlayerVersion from "swf-player-version"

import Video from "./video"

import "./flash_.css" // css

function Flash(props) {
  var url = props.block_content.swf

  var flash_height =
    Math.round((100 * 100) / props.block_content.aspect_ratio) / 100

  var flash_height_css = flash_height + "%"
  var background_color =
    typeof props.block_content.white != "undefined" ? "#FFFFFF" : "#FFFFFF"

  var style = {
    paddingBottom: flash_height_css,
    backgroundColor: background_color
  }

  if (SWFPlayerVersion.isSupported("10.0")) {
    return (
      <div className="flash-container">
        <div className="flash-content" style={style}>
          <ReactSWF
            src={url}
            id="guid_001"
            width="100%"
            height="100%"
            wmode="transparent"
            flashVars={{ foo: "A", bar: 1 }}
          />
        </div>
      </div>
    )
  } else {
    return <Video block_content={props.block_content.fallback} />
  }
}

export default Flash
