//
//     ___          ___        ____
//    / _ \__ _____/ (_)__    / __/    _____ ____ ____ ______ _  ___ ____
//   / , _/ // / _  / / _ \  _\ \| |/|/ / _ `/ _ `/ -_) __/  ' \/ _ `/ _ \
//  /_/|_|\_,_/\_,_/_/_//_/ /___/|__,__/\_,_/\_, /\__/_/ /_/_/_/\_,_/_//_/
//   2013                                  /___/
//
// Hoeveel thumbs per rij? Hoogte van venster moeten twee items passen?
// Dus: breedte/(hoogte/2) is het aantal per rij...

// Ideeeee wanneer je wegscrolled van het geopende 'project' dan sluit ie gewoon met een vertraging van 1/2 sec weetje!

// vervolgens deze strook een hoogte 100 geven, kijken hoe breed het geheel wordt.
// vervolgens kijken hoe breed ie eigenlijk moet worden
// en dan de resulterende hoogte bepalen voor dit stukje plaatjesstrookie ;-]

// !! var image_ratios = new Array();
var thumbs_w = 0

// een flinke reeks maken met de breedte van de thumbs ten opzichte van een lading vierkantjes...
// kijken bij welke count je het dichtst bij de hoeveelheid vierkantjes komt...
// vervolgens deze strook een hoogte 100 geven, kijken hoe breed het geheel wordt.
// vervolgens kijken hoe breed ie eigenlijk moet worden
// en dan de resulterende hoogte bepalen voor dit stukje plaatjesstrookie ;-]

// screen width... wel nodig die erbij te betrekken?!!!
// is zodat de thumbs ook bij een wat kleiner venster, maar WEL op dezelfde imac niet heel erg gaan varieren qua formaat..
var key_points = new Array()
key_points.push({ resolution: 2560, count: 10 })
key_points.push({ resolution: 1500, count: 6 })
key_points.push({ resolution: 1024, count: 5 })
key_points.push({ resolution: 768, count: 4 })
key_points.push({ resolution: 320, count: 1 })

var grow_factor = 0.45

var horizontal_spacing = 82
var vertical_spacing = 58

var resulting_rows = []

var this_factor = 3.5

var scalableGrid = {
  thumbSizing: function(image_ratios, container_width, factor) {
    grow_factor = factor

    resulting_rows = []

    var screen_w = window.screen.width

    var got_it = false
    for (var i = 0; i < key_points.length; i++) {
      if (screen_w >= key_points[i].resolution && !got_it) {
        var how_many = key_points[i].count
        if (i - 1 > 0) {
          var add_some =
            ((key_points[i - 1].count - key_points[i].count) /
              (key_points[i - 1].resolution - key_points[i].resolution)) *
            (screen_w - key_points[i].resolution)
          how_many += add_some
        }
        got_it = true
      }
    }

    var how_many = how_many * grow_factor
    // now, how many to be shown in this window...
    // var window_w = window.innerWidth-100;
    thumbs_w = container_width
    ///////// document.getElementById("body").offsetWidth-40;
    // kenne we straks de width van de DIV voor pakken, aight!!!
    // !! document.getElementById("thumbs").style.width = thumbs_w+'px';

    if (thumbs_w < screen_w) {
      how_many = (how_many / screen_w) * thumbs_w
    }
    //
    // kijken wanneer je in de buurt van deze how_many komt
    var this_row = 0
    var row_length = 0
    var row_width = 0
    var start_at = 0
    var row_widths = []
    var row_lengths = []
    var min_row = -1
    var max_row = -1
    var image_aspect
    var next_image_aspect
    var image_aspect_refactored
    var next_image_aspect_refactored

    // var aspect_refactored = image_aspect*(((1/image_aspect)+(this_factor-1))/this_factor);

    for (var i = 0; i < image_ratios.length; i++) {
      image_aspect = image_ratios[i].aspect_ratio
      image_aspect_refactored =
        image_aspect * ((1 / image_aspect + (this_factor - 1)) / this_factor)
      if (image_ratios.length > i + 1) {
        next_image_aspect = image_ratios[i + 1].aspect_ratio
        next_image_aspect_refactored =
          next_image_aspect *
          ((1 / next_image_aspect + (this_factor - 1)) / this_factor)
      } else {
        next_image_aspect = 0
        next_image_aspect_refactored = 0
      }

      this_row += image_aspect_refactored
      // kijken of de rij al vol genoeg is of dat er beter nog 1 bij kan?
      row_length++
      if (this_row + next_image_aspect_refactored > how_many) {
        if (
          Math.abs(this_row + next_image_aspect_refactored - how_many) >
          Math.abs(this_row - how_many)
        ) {
          this.createFullRow(image_ratios, start_at, row_length, this_row)
          if (this_row < row_widths[min_row] || min_row == -1) {
            min_row = row_widths.length
          }
          if (this_row > row_widths[max_row] || max_row == -1) {
            max_row = row_widths.length
          }
          row_widths[row_widths.length] = this_row
          row_lengths[row_lengths.length] = row_length
          // reset
          start_at = i + 1
          this_row = 0
          row_length = 0
        }
      }
    }
    // ONDERSTAANDE is nog een beetje rommelig hoor: even helder opsommen wat precies de scenarios zijn wat betreft de laatste beelden!!
    // Waar zit het kantelpunt?! wanneer weeskindjes, of wormvormig aanhangsel, wanneer rij in volle breedte tonen?! wanneer het (erbinnen valt) een bepaalde factor van het optimale is eigenlijk he?!

    // en nu de laatste voor het geval er een paar over blijven
    var last_row = this_row
    var last_row_length_for_spaces = row_length
    ///// how_many;

    // this_row : dat is de optelsom van de aspects van de laatste beelden!!!?!
    // dat moet dus prima kunnen als het er bijvoorbeeld drie zijn ofzo?!
    // how_many: dat is de optimale hoeveelheid, weetje...
    // ZO!!!! GVD laatste rij is prima, uitvullen die handel:
    if (
      (last_row < row_widths[max_row] && last_row > row_widths[min_row]) ||
      (last_row + 0.6666 > how_many && last_row > row_widths[min_row] * 0.75)
    ) {
      // !- uitvullen deze rij!!!
      // !- als er een portretje bij zou passen: prima, maar de laatste rij moet simpelweg niet te extreem afwijken van de bovenstaande
      // eventjes uit, want percentages sucken?!!!!
      //     || last_row > how_many*0.75
      // of als er nog een portretplaatje bij past?! of een 0.5....
      //
      // of deze zelfs wat breder is dan 'optimaal'?! uitvullen die handel!!
      // prima dan! niks meer aan doen! neerzetten die handel!
    } else {
      // !- deze laatste rij de meest optimale afmetinen geven: uitgevuld wordt ie niet, maar laat 'm in formaat mooi aansluiten bij de rest

      ///  if ((how_many<this_row && row_length < 2) || how_many < 1.75 || (row_length < 2 && how_many > 2))
      last_row = how_many
      //  && row_widths[i]<how_many
      if (row_widths.length >= 0) {
        last_row = row_widths[0]
        last_row_length_for_spaces = row_lengths[0]
        // MAAR als er maar 1 rij boven staat is het mooier om het gemiddelde te nemen van de hoogte en meest optimale?! neeh.. fukda
        // KOM ik nu tegen: wanneer de hoogte van de laatste rij meer dan 25% afwijkt van de anderen..... dan niet uitvullen
      }
      // MAAR deze moet dus dezelfde size als de vorige rij krijgen als die een beetje schappelijk formaat heeft?!
      // dus ik moet nog weten hoeveel er ook alweer op die rij stonden
      // kijken in de row widths welke het meest optimaal is en die dan als uitgangspunt nemen he... vergelijken en huppa!
      // welke het dichtstbij optimaaaaal komt...

      // AH ja!!! dan moeten die margins oook in het geval 't de laatste rij betreft eh, ook dezelfde als hoeveel op de optimale rij staan eraf halen gnegne!!

      for (var i = 1; i < row_widths; i++) {
        if (
          Math.abs(row_widths[i] - this_row) <
          Math.abs(last_row - row_widths[i])
        ) {
          //  && row_widths[i]<how_many
          last_row = row_widths[i]
          last_row_length_for_spaces = row_lengths[i]
        }
      }
    }
    this.createFullRow(
      image_ratios,
      start_at,
      row_length,
      last_row,
      last_row_length_for_spaces
    )
    console.log(resulting_rows.length)
    return resulting_rows
  },

  createFullRow: function(
    image_ratios,
    start_at,
    row_length,
    row_width,
    last_row
  ) {
    var num_spaces = row_length - 1
    if (last_row) {
      num_spaces = last_row - 1
    }
    var total_width = thumbs_w - num_spaces * horizontal_spacing
    var row_scale = total_width / row_width
    var this_row = []
    for (var i = start_at; i < start_at + row_length; i++) {
      // console.log(i);
      // aspect*(((1/aspect)+(this_factor-1))/factor)
      var image_aspect = image_ratios[i].aspect_ratio
      var aspect_refactored =
        image_aspect * ((1 / image_aspect + (this_factor - 1)) / this_factor)

      //////// console.log(image_aspect+"  "+aspect_refactored+"row_scale"+row_scale+"total_width"+total_width+"row_width"+row_width);

      var thumb_width = row_scale * aspect_refactored
      var thumb_height = thumb_width / image_aspect

      // ///////console.log(thumb_width+'   '+thumb_height);
      //
      // !! thumbs[i].style.width = thumb_width+'px';
      // !! thumbs[i].style.height = thumb_height+'px';
      // neen, margin top is de hoogte die een beeld met factor 2/3 zou innemen...
      var max_image_aspect = 2 / 3
      var max_aspect_refactored =
        max_image_aspect *
        ((1 / max_image_aspect + (this_factor - 1)) / this_factor)
      var max_height = (row_scale * max_aspect_refactored) / max_image_aspect

      var this_image = {
        id: i,
        width: thumb_width,
        height: thumb_height
      }

      //////////// console.log(this_image);

      // !! thumbs[i].style.marginTop = (max_height-thumb_height)/2+'px';
      this_image.top = (max_height - thumb_height) / 1
      if (i == start_at) {
        // !!   thumbs[i].style.clear = 'both';
        this_image.first = true
      } else {
        // !!   thumbs[i].style.clear = 'none';
      }
      if (i == start_at + row_length - 1) {
        // !!   thumbs[i].style.marginRight = 0;
        this_image.right = 0
      } else {
        // !!   thumbs[i].style.marginRight = horizontal_spacing+'px';
        this_image.right = horizontal_spacing
      }
      /*
    if (last_row) {
    // !!   thumbs[i].style.marginBottom = 0;
    this_image.bottom = 0;
    } else {
    // !!   thumbs[i].style.marginBottom = vertical_spacing+'px';
    this_image.bottom = vertical_spacing;
    }
    */
      this_image.bottom = vertical_spacing

      // Kan ik nu wat meer random stopp'n in de grootte waarop de beelden worden getoond?! NET iets kleiner dan 100%?!
      // per beeld onload dat wel vastleggen, anders danst de boel wel erg in de rondte...
      // maar: zou mooi zijn toch?
      this_row.push(this_image)
    }
    resulting_rows.push(this_row)
  }
}
//

export default scalableGrid
