import React, { Fragment } from "react"

import Header from "./contentblocks/header"
import Video from "./contentblocks/video"
import SmallVideo from "./contentblocks/video_small"
import Image from "./contentblocks/image"
import SmallImage from "./contentblocks/image_small"
import Paragraph from "./contentblocks/paragraph"
import LinkBlock from "./contentblocks/link"
import Iframe from "./contentblocks/iframe"
import Flash from "./contentblocks/flash"

import Year from "./contentblocks/year"

import ConfettiContainer from "../confetti/confetticontainer"
import categories from "../../hoo"

import "./contentcontainer_.css"

function ContentContainer(props) {
  var project_data = props.project_data
  // per content block kijken waffur block het is: video, text, image, hele gallery?
  // laten we beginnen met het loopen door de blocks:
  if (typeof project_data.content != "undefined") {
    var contentBlocks = project_data.content.map(function (block, index) {
      if (typeof block.confetti != "undefined") {
        return (
          <div className="ratio-container">
            <div className="ratio-content">
              <ConfettiContainer key={"confetti" + index} />
            </div>
          </div>
        )
      } else if (
        typeof block.small != "undefined" &&
        typeof block.video != "undefined"
      ) {
        return <SmallVideo key={"smallvideo" + index} block_content={block} />
      } else if (
        typeof block.small != "undefined" &&
        typeof block.image != "undefined"
      ) {
        return <SmallImage key={"smallimage" + index} block_content={block} />
      } else if (typeof block.video != "undefined") {
        return <Video key={"video" + index} block_content={block} />
      } else if (typeof block.image != "undefined") {
        return <Image key={"image" + index} block_content={block} />
      } else if (typeof block.paragraph != "undefined") {
        return <Paragraph key={"p" + index} block_content={block} />
      } else if (typeof block.link != "undefined") {
        return <LinkBlock key={"a" + index} block_content={block} />
      } else if (typeof block.header != "undefined") {
        return <Header key={"h" + index} block_content={block} />
      } else if (typeof block.iframe != "undefined") {
        return <Iframe key={"h" + index} block_content={block} />
      } else if (typeof block.swf != "undefined") {
        return <Flash key={"h" + index} block_content={block} />
      } else {
        return <div key={index}>{index}</div>
      }
    })
  } else {
    var contentBlocks = null
  }
  return (
    <div className="content-container">
      {contentBlocks}
      <Year project_data={project_data} />
      {project_data.title === "House Of Orange" && (
        <div>
          <h1
            style={{ fontSize: "1em", marginTop: "3rem", fontWeight: "normal" }}
          >
            Visit the artists' portfolios:
          </h1>
          {categories.map((category, index) => (
            <div key={category.name} style={{ fontSize: "0.75rem" }}>
              <h1 style={{ fontSize: "1em", marginBlockEnd: 0 }}>
                {category.name}
              </h1>
              <ul
                style={{
                  listStyleType: "none",
                  marginBlockStart: 0,
                  paddingInlineStart: 0,
                }}
              >
                {category.artists.map((artist, index) => (
                  <li>
                    <a
                      href={artist.to}
                      target="_blank"
                      rel="noreferrer"
                      key={artist.name}
                      style={{ textDecoration: "none" }}
                    >
                      {artist.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ContentContainer
