import React from "react"

import "./image_small_.css" // css
import Image from "../../Image"
import Dimensions from "../../Dimensions"

function SmallImage(props) {
  var image_url = props.block_content.image

  var image_aspect_ratio = props.block_content.aspect_ratio

  var image_height = Math.round((100 * 100) / image_aspect_ratio) / 100
  var image_height_css = image_height + "%"

  // er moet nog een max-width gekoppeld worden aan de ratio?! liefst zodat een portret-beeld ongeveer hetzelfde oppervlak krijgt?!
  // max-width: vmin
  //
  // var max_width_factor = 0.9;
  // var max_image_width = Math.round(100*100*image_aspect_ratio*max_width_factor)/100;
  // var max_image_width_css = max_image_width+'vh';

  var container_style = {
    // maxWidth: max_image_width_css
  }

  var style = {
    paddingBottom: image_height_css,
  }

  return (
    <div className="small-image-container" style={container_style}>
      <Dimensions
        className="small-image-content"
        style={style}
        key="image_content"
      >
        {(canvas) => (
          <Image
            width={1000}
            height={1000 * image_aspect_ratio}
            url={image_url}
            canvas={canvas}
          />
        )}
      </Dimensions>
    </div>
  )
}

export default SmallImage
